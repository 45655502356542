@import '../styles/main.scss';

.main {
  margin: 0 auto;
  padding-left: 6rem;
  padding-right: 6rem;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 5rem;

  @include respond(medium) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0;
  }

  @include respond(x-large) {
    width: 50%;
  }

  .sorryText {
    font-weight: 700;
    font-size: 30px;
    color: #5d607c;
    margin: 1em 0;

    @include respond(medium) {
      & > #sorry-text-404 {
        display: block;
        font-weight: 900;
        font-size: 35px;
        line-height: 42px;
        letter-spacing: 0.01em;
      }

      & > #sorry-text-hyphen {
        display: none;
      }

      & > #sorry-text-remaining {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.01em;
      }
    }
  }

  .infoText {
    font-weight: 500;
    font-size: 20px;
    color: #0b0e34;
    margin: 1em 0;
  }
}
