@import '../../styles/main.scss';

.productButtons {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 2rem;

  @include respond(medium) {
    grid-template-columns: repeat(1, 1fr);
  }

  @include respond(medium) {
    margin-top: 4rem;
  }
}

.productButton {
  display: flex;
  border: 1px solid #e4eaf1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px;
  align-items: center;
  cursor: pointer;

  @include respond(medium) {
    margin: 0;
    width: inherit;
  }
}

.productTitle {
  padding-left: 16px;
  box-sizing: border-box;
  font-weight: 700;
}

.rightArrowContainer {
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-left: auto;
}
